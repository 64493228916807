<template>
  <div>
    <b-table-custom
        :table-data="dataObjectList"
        :table-columns="tableColumns"
        :is-data-loading="isDataLoading"
        @selected-item-onchange="selectedItemOnChange"
    >
      <template #button_event >
        <b-dropdown class="menu-button"
            variant="primary"
            size="sm"
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon" class="mr-1"/>
            <span>Action Menu</span>
          </template>
          <b-dropdown-item v-for="(menuItem, index) in actionMenuList"
                           :key="index"
                           @click="openModal(menuItem['name'])"
          >
            <font-awesome-icon :icon="menuItem['icon']" :class="`text-${menuItem['color']}`" />
            <span>{{ menuItem['label'] }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table-custom>

    <custom-modal
        id="action-menu-modal"
        ref="action-menu-modal"
        :title="currentModal['title']"
        :ok-title="currentModal['okButton']"
        :cancel-title="currentModal['cancelButton']"
        :variant="currentModal['variant']"
        @ok="currentModal['okFunction']"
        :is-manual-close="true"
    >
      <b-card-text v-if="modalName === 'modal-assign-vps'">
        <custom-select v-model="objectAssign.planId"
                       :options="planList"
                       label="Plan"
                       reduce="id"
                       display-field="name"
        >
          <template #option="{item}">
            <span><small><strong>{{ item['name'] }}</strong> (<span class="text-success">{{ currencyFormat(item['selling_price']) }}</span>)</small></span>
          </template>
          <template #selected-option="{item}">
            <span><small><strong>{{ item['name'] }}</strong> (<span class="text-success">{{ currencyFormat(item['selling_price']) }}</span>)</small></span>
          </template>
        </custom-select>
        <custom-select v-model="objectAssign.ownerUuid"
                       reduce="uuid"
                       label="Owner"
                       :options="userList"
                       display-field="user_name"
        >
          <template #option="{item}">
            <span><small><strong>{{ item['user_name'] }}</strong> ({{ item['facebook_name'] }} - {{ item['email'] }})</small></span>
          </template>
          <template #selected-option="{item}">
            <span><strong>{{ item['user_name'] }}</strong> ({{ item['facebook_name'] }} - {{ item['email'] }})</span>
          </template>
        </custom-select>
        <b-form-group>
          <label>Note</label>
          <b-form-textarea
              rows="2"
              v-model="objectAssign.note"
          />
        </b-form-group>
        <b-form-group>
          <label>Admin Note</label>
          <b-form-textarea
              rows="2"
              v-model="objectAssign.adminNote"
          />
        </b-form-group>
        <b-row>
          <b-col sm="6">
            <date-time-picker
                :is-show-add-time="true"
                :is-show-minus-time="true"
                v-model="objectAssign.startDate" label="Start Date"
            />
          </b-col>
          <b-col sm="6">
            <date-time-picker
                :is-show-add-time="true"
                :is-show-minus-time="true"
                v-model="objectAssign.endDate" label="End Date"
            />
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text v-else-if="modalName === 'renew-vps'">
        <item-list :items="selectedItems"
                   :label="`${selectedItems.length} VPS sẽ được gia hạn`"
        >
          <template #item-label="{data}">
            <span>{{ getIPPortFormat(data['ip'], data['port'], data['os']) }}</span>
          </template>
        </item-list>
        <custom-select v-model="objectRenew.planId"
                       :options="planList"
                       label="Plan"
                       reduce="id"
                       display-field="name"
        >
          <template #option="{item}">
            <span><small><strong>{{ item['name'] }}</strong> (<span class="text-success">{{ currencyFormat(item['selling_price']) }}</span>)</small></span>
          </template>
          <template #selected-option="{item}">
            <span><small><strong>{{ item['name'] }}</strong> (<span class="text-success">{{ currencyFormat(item['selling_price']) }}</span>)</small></span>
          </template>
        </custom-select>
        <custom-input v-model="objectRenew.period"
                      label="Period"
                      type="number"
        />
        <b-form-group>
          <label>Note</label>
          <b-form-textarea
              rows="2"
              v-model="objectRenew.note"
          />
        </b-form-group>
      </b-card-text>
    </custom-modal>
  </div>
</template>

<script>
import BTableCustom from '@/views/components/table/BTableCustom.vue'
import DateTimePicker from "@/views/components/form/DateTimePicker.vue"
import CustomSelect from "@/views/components/form/CustomSelect.vue"
import CustomModal from "@/views/components/CustomModal.vue"
import ItemList from "@/views/components/ItemList.vue"
import CustomInput from "@/views/components/form/CustomInput.vue";

import useJwt from '@/auth/jwt/useJwt'
import planService from "@/mixins/utils/axios/plan.service";
import inputHelper from '@/mixins/utils/inputHelper'
import dataRequester from '@/mixins/utils/dataRequester'
import dataObjectList from '@/mixins/utils/dataObjectList'
import notificationHelper from "@/mixins/notificationHelper"
import {mainStore} from "@/store/pinia/main.store";

import moment from 'moment'
import cloudVpsService from "@/mixins/utils/axios/cloudVps.service";
import invoiceService from "@/mixins/utils/axios/invoice.service";
import userService from "@/mixins/utils/axios/user.service";

export default {
  name: "Storage.vue",
  components: {
    CustomModal,
    BTableCustom,
    DateTimePicker,
    CustomSelect,
    ItemList,
    CustomInput
  },
  mixins: [inputHelper, dataRequester, dataObjectList, notificationHelper],
  computed: {
    currentModal: function () {
      switch(this.modalName) {
        case 'modal-assign-vps':
          return {
            title: 'Assign Cloud Vps',
            variant: 'danger',
            okFunction: this.assignVpsForUser
          }
        case 'renew-vps':
          return {
            title: 'Renew Cloud Vps',
            variant: 'success',
            okFunction: this.createInvoice
          }
        default:
          return {
            title: '',
            variant: 'primary',
            okButton: 'OK',
            cancelButton: 'Cancel',
            okFunction: function () {
              console.log('default')
            }
          }
      }
    }
  },
  data: function () {
    return {
      tableColumns: [
        {
          key: 'ip_port',
          label: this.$t('cloudVps.table.header.ip_port'),
          formatter (value) {
            return `<a href="javascript:;" class="font-weight-bold">${value}</a>`
          }
        },
        {
          key: 'vm_plan_name',
          label: 'LEV Plan', //this.$t('cloudVps.table.header.plan'),
          formatter (value) {
            return `<strong class="text-info">${value}</strong>`
          }
        },
        // {
        //   key: 'plan',
        //   label: this.$t('cloudVps.table.header.plan'),
        //   formatter (value, index, item) {
        //     return `<strong class="text-info">${item['plan']['name']}</strong>
        //             <br/>
        //             <span class="text-success">${self.currencyFormat(item['price'])}</span>`
        //   }
        // },
        // {
        //   key: 'region',
        //   label: this.$t('cloudVps.table.header.region')
        // },
        {
          key: 'start_date',
          label: this.$t('cloudVps.table.header.start_date')
        },
        {
          key: 'end_date',
          label: this.$t('cloudVps.table.header.end_date')
        },
        {
          key: 'os_type',
          label: this.$t('cloudVps.table.header.os'),
          formatter (value, index, item) {
            return `${item['os_type']} (${item['os_version']})`
          }
        },
        {
          key: 'user_remark',
          label: this.$t('cloudVps.table.header.note')
        },
        {
          key: 'payment_status',
          label: this.$t('cloudVps.table.header.payment_status')
        },
        // {
        //   key: 'operation_status',
        //   label: this.$t('cloudVps.table.header.operation_status')
        // },
        // {
        //   key: 'cloud_vps_menu',
        //   label: this.$t('cloudVps.table.header.manage')
        // }
      ],
      isDataLoading: false,
      selectedItems: [],
      objectAssign: {
        levServiceIds: [],
        planId: null,
        ownerUuid: null,
        note: null,
        adminNote: null,
        startDate: null,
        endDate: null
      },
      objectRenew: {
        planId: null,
        period: 1,
        note: null
      },
      planList: [],
      userList: [],
      modalName: null,
      actionMenuList: [
        {
          name: 'modal-assign-vps',
          label: 'Gán VPS',
          icon: 'fa-solid fa-plus',
          color: 'success'
        },
        {
          name: 'renew-vps',
          label: 'Tạo hoá đơn gia hạn',
          icon: 'fa-solid fa-calendar-plus',
          color: 'info'
        }
      ]
    }
  },
  created() {
    let self = this
    this.isDataLoading = true

    this.runApi(cloudVpsService.listAllNotActiveCloudVPS(), function (result) {
      self.dataObjectList = result.data.sort((a, b) => a['end_date'].localeCompare(b['end_date']))
      self.isDataLoading = false
    })
    this.runApi(planService.getPlans(), function (result) {
      self.planList = self.sortByField(result.data.filter(item => item['is_active']), 'name')
    })
    this.runApi(userService.listAllUsers(), function (result) {
      self.userList = result.data
    })
  },
  methods: {
    // checkOperationStatus (rowData) {
    //   rowData['operation_status'] = 'Checking'
    //   console.log(rowData)
    //   this.runApi(useJwt.getCloudVpsOperationStatus(rowData['id']), function (result) {
    //     rowData['operation_status'] = result.data['operation_status']
    //   }, function () {
    //     rowData['operation_status'] = 'Unknown'
    //   })
    // },
    selectedItemOnChange(selectedItems) {
      this.selectedItems = selectedItems
    },
    openModal(modalName) {
      this.modalName = modalName

      if (this.selectedItems.length === 0) {
        this.popupError('Bạn cần chọn ít nhất một giá trị để thực hiện thao tác')
        return
      }

      if (modalName === 'renew-vps') {
        this.objectRenew.note = this.selectedItems[0]['user_remark']
        let planUnique = [...new Set(this.selectedItems.map(item => item['vm_plan_name']))]
        if (planUnique.length > 1) {
          this.popupError('Không thể tạo hoá đơn gia hạn cho các VPS có các plan khác nhau')
          return
        }
      }

      this.$refs['action-menu-modal'].show()
    },
    closeModal() {
      this.$refs['action-menu-modal'].hide()
    },

    assignVpsForUser() {
      let self = this
      let levServiceIds = this.selectedItems.map(item => item['lev_service_id'])
      this.runApi(cloudVpsService.assignCloudVps({
        levServiceIds: levServiceIds,
        planId: self.objectAssign.planId,
        ownerUuid: self.objectAssign.ownerUuid,
        ownerUsername: self.userList.find(item => item['uuid'] === self.objectAssign.ownerUuid)['user_name'],
        note: self.objectAssign.note,
        adminNote: self.objectAssign.adminNote,
        startDate: self.objectAssign.startDate === null ? undefined : moment(self.objectAssign.startDate).format('YYYY-MM-DD'),
        endDate: self.objectAssign.endDate === null ? undefined : moment(self.objectAssign.endDate).format('YYYY-MM-DD')
      }), function () {
        self.dataObjectList = self.dataObjectList.filter(item => !levServiceIds.includes(item['lev_service_id']))
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
    createInvoice() {
      let self = this
      this.runApi(invoiceService.hardCreateInvoice({
        type: 'renew',
        planId: self.objectRenew['planId'],
        period: self.objectRenew['period'],
        note: self.objectRenew['note'],
        items: self.selectedItems.map(x => {
          return {
            lev_service_id: x['lev_service_id'],
            note: x['user_remark'],
            type: 'vps',
            end_date: x['end_date'],
            ip: x['ip'],
            port: x['port']
          }
        }),
      }), function () {
        const selectedPlan = self.planList.find(item => item['id'] === self.objectRenew['planId'])
        mainStore().levBalanceChange(selectedPlan['actual_buying_price'] * self.objectRenew['period'] * self.selectedItems.length * -1)
      }, undefined, function () {
        self.closeModal()
      }, true)
    },
  }
}
</script>
