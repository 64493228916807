import utilsService from "@/mixins/utils/axios/utils.service";
const planEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/plan`;
const planTypeEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/plan-type`;

export default {
    getPlans() {
        return utilsService.getRequest(planEndpoint);
    },
    activatePlan(planId) {
        return utilsService.putRequest(`${planEndpoint}/activate`, {id: planId});
    },
    deactivatePlan(planId) {
        return utilsService.putRequest(`${planEndpoint}/deactivate`, {id: planId});
    },
    listPlanType () {
        return utilsService.getRequest(`${planTypeEndpoint}`);
    },
    updatePlan (...args) {
        return utilsService.putRequest(`${planEndpoint}/update`, ...args);
    },
    addNewPlan (...args) {
        return utilsService.postRequest(`${planEndpoint}/add-new`, ...args);
    }
}
