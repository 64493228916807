<template>
  <b-form-group>
    <label>{{ label }}</label>
    <v-select
      :value="value"
      :options="options"
      :reduce="item => (reduce === undefined ? item : item[reduce])"
      :label="displayField"
      :size="size"
      :placeholder="placeholder"
      @input="updateValue"
      :clearable="false"
      :filter-by="filterBy"
    >
    <template #option="obj">
      <slot name="option" :item="obj"/>
    </template>
    <template #selected-option="obj">
      <slot name="selected-option" :item="obj"/>
    </template>
    </v-select>
  </b-form-group>
</template>

<script>

import vSelect from 'vue-select'
import inputHelper from "@/mixins/utils/inputHelper";

export default {
  name: 'CustomSelect',
  mixins: [inputHelper],
  components: {
    vSelect
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    size: {
      type: String,
      default: undefined
    },
    reduce: {
      type: String,
      default: 'id'
    },
    displayField: {
      type: String,
      required: true,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
    filterBy (option, label, search) {
      let temp = this.removeVietnameseDiacritics(search.toLowerCase());
      return Object.keys(option).some(key =>
          ![null, undefined].includes(option[key]) &&
          typeof option[key] !== 'object' &&
          this.removeVietnameseDiacritics(option[key].toString().toLowerCase()).indexOf(temp) > -1
      );
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
