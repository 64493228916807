<template>
  <b-form-group>
    <label>{{ label }}</label>
    <b-input-group>
      <b-input-group-prepend v-if="isShowMinusTime">
        <b-button variant="danger" @click="subtractTime">
          <font-awesome-icon icon="fa-solid fa-minus" />
        </b-button>
      </b-input-group-prepend>
      <flat-pickr
          :value="value"
          class="form-control"
          @input="updateValue"
      />
      <b-input-group-append v-if="isShowAddTime">
        <b-button variant="success" @click="addTime">
          <font-awesome-icon icon="fa-solid fa-plus" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>

</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import inputHelper from "@/mixins/utils/inputHelper";

export default {
  name: 'DateTimePicker',
  mixins: [inputHelper],
  components: {
    flatPickr
  },
  props: {
    value: {
      type: String,
      default () {
        const currentDate = new Date()
        return `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
      }
    },
    label: {
      type: String,
      default: ''
    },
    timeUnit: {
      type: String,
      default: 'months'
    },
    isShowAddTime: {
      type: Boolean,
      default: false
    },
    isShowMinusTime: {
      type: Boolean,
      default: false
    }
  },
  updated() {
    this.dateTime = this.value
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
    addTime() {
      let dateTime = this.momentAdd(this.dateTime, 1, this.timeUnit)
      this.$emit('input', dateTime)
    },
    subtractTime() {
      let dateTime = this.momentAdd(this.dateTime, -1, this.timeUnit)
      this.$emit('input', dateTime)
    }
  }
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
