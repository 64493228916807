import { createPinia, defineStore } from 'pinia'

const pinia = createPinia()

export default pinia

export const mainStore = defineStore('main', {
    state: () => ({
        levBalance: 0
    }),
    actions: {
        setLevBalance (val) {
            this.levBalance = val
        },
        levBalanceChange (val) {
            this.levBalance = parseFloat(this.levBalance) + parseFloat(val)
        }
    }
})
