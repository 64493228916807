import utilsService from "@/mixins/utils/axios/utils.service";
const cloudVpsEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/cloud-vps`;

export default {
    listAllActiveCloudVPS (cloudVpsIds) {
        return utilsService.getRequest(`${cloudVpsEndpoint}`, {id: cloudVpsIds});
    },
    listAllNotActiveCloudVPS () {
        return utilsService.getRequest(`${cloudVpsEndpoint}/not-assigns`);
    },
    getCloudVpsOperationStatus (cloudVpsId) {
        return utilsService.getRequest(`${cloudVpsEndpoint}/status`, {id: cloudVpsId});
    },
    startVPS (cloudVpsId) {
        return utilsService.getRequest(`${cloudVpsEndpoint}/start`, {id: cloudVpsId});
    },
    stopVPS (cloudVpsId) {
        return utilsService.getRequest(`${cloudVpsEndpoint}/stop`, {id: cloudVpsId});
    },
    restartVPS (cloudVpsId) {
        return utilsService.getRequest(`${cloudVpsEndpoint}/restart`, {id: cloudVpsId});
    },
    deleteCloudVps (cloudVpsId) {
        return utilsService.deleteRequest(`${cloudVpsEndpoint}/delete`, {id: cloudVpsId});
    },
    suspendCloudVps (cloudVpsId) {
        return utilsService.getRequest(`${cloudVpsEndpoint}/suspend`, {id: cloudVpsId});
    },
    activeCloudVps (cloudVpsId) {
        return utilsService.getRequest(`${cloudVpsEndpoint}/active`, {id: cloudVpsId});
    },
    updateCloudVps (...args) {
        return utilsService.putRequest(`${cloudVpsEndpoint}/update`, ...args);
    },
    assignCloudVps (...args) {
        return utilsService.postRequest(`${cloudVpsEndpoint}/assign`, ...args);
    },
    getAddonList (cloudVpsId) {
        return utilsService.getRequest(`${cloudVpsEndpoint}/addons`, {id: cloudVpsId});
    },
    addAddonActive (...args) {
        return utilsService.postRequest(`${cloudVpsEndpoint}/addon/add`, ...args);
    }
}
