import utilsService from "@/mixins/utils/axios/utils.service";
const invoiceEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/invoice`;

export default {
    createInvoice (...args) {
        return utilsService.postRequest(`${invoiceEndpoint}/create-invoice`, ...args);
    },
    hardCreateInvoice (...args) {
        return utilsService.postRequest(`${invoiceEndpoint}/hard-create-invoice`, ...args);
    },
    cancelInvoice (...args) {
        return utilsService.putRequest(`${invoiceEndpoint}/cancel`, ...args);
    },
    paymentInvoice (...args) {
        return utilsService.postRequest(`${invoiceEndpoint}/payment`, ...args);
    },
    listAllInvoices () {
        return utilsService.getRequest(`${invoiceEndpoint}`);
    },
    getInvoiceDetail (invoiceId) {
        return utilsService.getRequest(`${invoiceEndpoint}/detail/${invoiceId}`);
    },
    getIncomeStatistic (months) {
        if (months !== undefined) {
            return utilsService.getRequest(`${invoiceEndpoint}/statistic/total-income?months=${months}`);
        } else {
            return utilsService.getRequest(`${invoiceEndpoint}/statistic/total-income`);
        }
    }
}
